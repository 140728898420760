.btn {
    background-color: $color-black;
    color: $color-white;
    border: 3px solid $color-black;
    border-radius: 10px;
    width: 385px;
    height: 80px;

    font-weight: normal;
    letter-spacing: 3.6px;
    font-size: $font-size-18;
    transition: all 0.2s ease-in;


    &:hover {
        transition: all 0.2s ease-in;
        background-color: $color-white;
        color: $color-black;
    }
}
