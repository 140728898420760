footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        margin: 0 auto;
        padding: 0 5%;
        @extend .items-center;
        @extend .text-color-grey;
        @extend .font-footer;

        .logo-copyright {
            .copyright {
                margin-top: 25px;
                font-weight: normal;
                font-size: $font-size-14;
                color: $color-white;
                opacity: 0.68;
            }
        }
        .section-menu {
            a.link {
                font-family: $font-family-2;
                font-weight: normal;
                font-size: $font-size-16;
                color: $color-white;
                text-transform: uppercase;
                transition: 0.3s;
                &:hover {
                    color: $color-1;
                    transition: 0.3s;
                }
            }
        }
        @media screen and (max-width: 1450px){
            .section-menu {
                a.link.mr8 {
                    margin-right: 1.5rem;
                }
            }
            @media screen and (max-width: 1215px){
                flex-direction: column;
                align-items: initial;
                .section-menu {
                    padding: 35px 0;
                }
                .section-mediaweb {
                    padding-bottom: 35px;
                }
                @media screen and (max-width: $size-xs-max) {
                    .section-menu {
                        flex-direction: column;
                        align-items: initial;
                        a.link.mr8 {
                            margin: 0 0 20px 0;
                        }
                    }
                }
            }
        }
    }



    @media screen and (max-width: 1215px){
        height: 350px;
        @media screen and (max-width: $size-xs-max) {
            height: 460px;
        }
    }
}
