$input_height: 50px;
$input_back_color: $bg-color-body;
$input_text_color: $text-color;
$input_text_size: $font-size-20;

$input_placeholder_color: $color-text-gris;
$input_placeholder_size: $font-size-18;

$input_label_color: $text-muted;
$input_label_size: $font-size-18;

$input_border_color: $color-primary;
$input_border_radius: $round-corner-radius; //based on variable
$input_border_size: 1px;
$input_border_style: solid;

$label_checkbox_border_color: $text-muted;
$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: $color-error;
$message_errors_border_color: $color-error;
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: $color-success;
$message_success_border_color: lighten($color-success, 20%);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: $bg-color-body;

form {
    input.form-control, textarea.form-control {
        height: 82px;
        color: $input_text_color;
        font-size: $input_text_size;
        background-color: #00000017;
        border: none;
        border-radius: 0;
    }
    input::placeholder, textarea::placeholder {
        font-weight: normal !important;
        font-size: $font-size-18;
        color: #899596 !important;
        padding-left: 20px !important;
    }
    textarea::placeholder {
        padding: 35px 20px !important
    }

    label {
        color: $input_label_color;
        font-size: $input_label_size;
    }
    label.title-list {
        background: #000;
        width: 100%;
        padding: 27px 35px;
        font-weight: normal;
        font-size: $font-size-28;
        text-transform: uppercase;
        text-align: initial;
        color: #EDEDED;
    }
    .title {
        text-transform: uppercase;
        color: $input_text_color;
        font-weight: bold;
        margin-top: 1em;
    }
    .center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .form-group {
        margin-bottom: -15px;
    }

    .red-border {
        background: red;
        width: 1920px;
        height: 1px;
        position: absolute;
        left: 0;
    }

    .partie-1 {
        .left-side {
            margin-top: -14px;
        }
        @media screen and (max-width: 1670px) {
            .list-checkBox.first {
                flex-wrap: wrap;
                label.item {
                    width: 45%;
                    &:first-child {
                        margin-bottom: 15px;
                    }
                }
            }
            .form-group {
                &.pb12 {
                    padding-bottom: 2.8rem;
                }
                &.pb13 {
                    padding-bottom: 3rem;
                }
            }
            @media screen and (max-width: $size-md-max) {
                .form-group {
                    &.pb12 {
                        padding-bottom: 3.9rem;
                    }
                }
                @media screen and (max-width: 1085px) {
                    .list-checkBox {
                        flex-wrap: wrap;
                        label.item {
                            width: 45%;
                            br {
                                display: none;
                            }
                            &:first-child {
                                margin-bottom: 15px;
                            }
                        }
                    }
                    .form-group {
                        &.pb12 {
                            padding-bottom: 2rem;
                        }
                        &.pb13 {
                            padding-bottom: 2rem;
                        }
                    }
                    @media screen and (max-width: $size-sm-max) {
                        .form-group {
                            &.pb12 {
                                padding-bottom: 2.5rem;
                            }
                            &.pb13 {
                                padding-bottom: 2.5rem;
                            }
                        }
                        @media screen and (max-width: $size-xs-max) {
                            flex-direction: column;
                            .left-side {
                                margin-bottom: 35px;
                            }
                            .list-checkBox {
                                flex-direction: column;
                                label.item {
                                    width: 100%;
                                    margin-bottom: 10px;
                                    &:first-child {
                                        margin-bottom: 10px;
                                    }
                                }
                                &.first {
                                    label.item {
                                        width: 100%;
                                        margin-bottom: 10px;
                                        &:first-child {
                                            margin-bottom: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .partie-2 {
        input {
            height: 50px;
            border-radius: 5px;
        }
        input::placeholder {
            font-size: $font-size-14;
            padding-left: 5px !important;
        }
        .border {
            background: #00000017;
            width: 100%;
            height: 1px;
            margin: 15px 0;
        }
        .section-checkbox {
            .left {
                text-align: initial;
                width: 33.3333%;
            }
            .center {
                text-align: initial;
                width: 33.3333%;
                margin: 0 25px;
            }
            .right {
                text-align: initial;
                width: 27%;
            }
        }
        .section-porte-color {
            height: 350px;
            .left-side {
                width: 35%;
                margin-right: 15px;
            }
            .right-side {
                width: 65%;
                .section-checkbox {
                    label.item {
                        width: 50%;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .section-lattes-hauteur {
            height: 200px;
            .left-side {
                width: 50%;
                margin-right: 35px;
            }
            .right-side {
                width: 50%;
            }
        }
        .section-installations-unites {
            .left-side {
                width: 50%;
                margin-right: 35px;
            }
            .right-side {
                width: 50%;
            }
        }
        @media screen and (max-width: 1540px) {
            label.title-list {
                font-size: 1.25rem;
            }
            @media screen and (max-width: 1410px) {
                label.title-list {
                    font-size: 1.1rem;
                }
                @media screen and (max-width: 1240px) {
                    flex-direction: column;
                    .right-side {
                        width: 100%;
                        .section-checkbox.first {
                            justify-content: space-between;
                        }
                        .section-installations-unites {
                            height: 280px;
                        }
                    }
                    .left-side {
                        width: 100%;
                        padding-bottom: 75px;
                        .section-images {
                            display: flex;
                            align-items: center;
                            .image.pb5 {
                                padding-bottom: 0;
                                padding-right: 15px;
                            }
                            &.second {
                                flex-wrap: wrap;
                                .image {
                                    width: 50%;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        padding-top: 0;
                        .left-side {
                            .section-images {
                                flex-direction: column;
                                .image.pb5 {
                                    padding-bottom: 15px;
                                    padding-right: 0;
                                }
                                &.second {
                                    .image {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                        .right-side {
                            .section-checkbox {
                                flex-direction: column;
                                &.first {
                                    .left, .right {
                                        width: 100%;
                                    }
                                    .center {
                                        width: 100%;
                                        margin: 5px 0;
                                    }
                                    label.item.mt8 {
                                        margin: 0;
                                    }
                                }
                            }
                            .section-porte-color {
                                flex-direction: column;
                                height: initial;
                                padding-top: 15px;
                                margin-bottom: -35px;
                                .left-side {
                                    width: 100%;
                                    margin: 0 0 -20px 0;
                                    padding-bottom: 0;
                                }
                                .right-side {
                                    width: 100%;
                                    label.item {
                                        text-align: initial;
                                        width: 100%;
                                    }
                                }
                            }
                            .section-lattes-hauteur {
                                flex-direction: column;
                                height: initial;
                                padding-top: 15px;
                                margin-bottom: -55px;
                                .left-side {
                                    width: 100%;
                                    margin: 0;
                                    padding-bottom: 0;
                                    .section-checkbox {
                                        align-items: initial;
                                    }
                                }
                                .right-side {
                                    width: 100%;
                                    .section-checkbox {
                                        align-items: initial;
                                        label.item.mx10 {
                                            margin: 0 0 5px 0;
                                        }
                                    }
                                }
                            }
                            .section-installations-unites {
                                flex-direction: column;
                                height: initial;
                                padding-top: 0;
                                .left-side {
                                    width: 100%;
                                    margin: 0 0 -30px 0;
                                    padding-bottom: 0;
                                }
                                .right-side {
                                    width: 100%;
                                    .section-checkbox {
                                        flex-direction: column;
                                        align-items: initial;
                                        label.item.mr4 {
                                            margin: 0 0 5px 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    a.accordion-toggle {
        display: flex;
        align-items: center;
        background: #000;
        width: 100%;
        height: 85px;
        padding: 0 50px 0 35px;
        label.title {
            margin: 0;
        }
        h4 {
            font-weight: normal;
            font-size: $font-size-18;
            line-height: 1.5;
            text-transform: initial;
            color: #EDEDED;
            text-align: initial;
        }
        .icon {
            .img-responsive {
                transform: rotate(130deg);
                transition: 0.5s;
            }
        }
    }
    a.accordion-toggle.collapsed {
        display: flex;
        align-items: center;
        
            .icon {
                .img-responsive {
                    transform: initial;
                    transition: 0.5s;
                }
            }
        
       
    }




    //// checkboxes and radiobuttons style ///////


    label.item {
        display: flex;
        position: relative;
        padding-left: 55px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-transform: inherit;
        height: 40px;
        align-items: center;
        transition: 0.3s;

        font-family: $font-family-3;
        font-weight: 500;
        font-size: $font-size-18;
        color: $color-black;
        text-transform: uppercase;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 40px;
        width: 40px;
        background-color: transparent;
        border: 3px solid $color-black;
        border-radius: 10px;
        transition: 0.3s;
    }

    /* On mouse-over, add a grey background color */
    .checkmark:hover input ~ .checkmark {
        background-color: #b8031b;
        transition: 0.3s;
    }

    /* When the checkbox is checked, add a blue background */
    .item input:checked ~ .checkmark {
        background-color: $color-1;
        border: 2px solid $color-1;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .item input:checked ~ .checkmark:after {
        display: none;
    }
    .item .checkmark:after {
        left: 10px;
        top: 6px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }


    .checkbox , .radio {
        display: flex;
        cursor: pointer;
        width: 100%;
        & > span {
            color: #024b94;
            padding: 0.5rem 0.25rem;
            margin-right: auto;
        }
        & >  input {
          height: 25px;
          width: 25px;
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          appearance: none;
          outline: none;
          transition-duration: 0.3s;
          background-color: $input_back_color;
          cursor: pointer;
          position: inherit!important;
        }
        & > input:checked {
          border: 3px solid $input_back_color;
          background-color: #024b94;
        }
    }

    .radio > input {
      border-radius: 50%;
    }

//// End checkboxes and radiobuttons style///////

    textarea {
        //margin: 2em 0;
        height: 280px !important;
    }



    .filedoc-btn {
        .left-side {
            width: 30%;
            //height: 80px;
            label.filedoc {
                font-family: $font-family-3;
                font-weight: 500;
                font-size: $font-size-18;
                color: $color-black;
                text-transform: uppercase;
                margin-bottom: 0;
            }
        }
        @media screen and (max-width: 1240px) {
            .right-side {
                margin-left: 50px;
                .btn {
                    width: 250px;
                }
            }
            @media screen and (max-width: 1140px) {
                align-items: self-end;
                .left-side {
                    label.filedoc br {
                        display: none;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    flex-direction: column;
                    align-items: center;
                    .left-side {
                        width: 100%;
                        text-align: center;
                        label.filedoc {
                            font-size: 1.2rem;
                        }
                    }
                    .right-side {
                        margin-left: 0;
                        margin-top: 25px;
                    }
                }
            }
        }
    }
}

.has-error {
    .form-control {
        color: $message_errors_text_color;
    }
}
.help-block {
    min-height: 15px;
}
.with-errors {
    li {
        line-height: 1;
    }
}



.messages {
    padding: 30px;
    margin-bottom: 2em;
    &.errors, &.success {
        p {
            font-size: $font-size-20;
            line-height: 1;
            margin: 0
        }
    }
    &.errors {
        background-color: $message_errors_background_color;
        border-radius: $message_errors_border_radius;
        border: $message_errors_border_size solid $message_errors_border_color;
        color: $message_errors_text_color;
        p {
            color: $message_errors_text_color;
        }
    }
    &.success {
        background-color: $message_success_background_color;
        border-radius: $message_success_border_radius;
        border: $message_success_border_size solid $message_success_border_color;
        color: $message_success_text_color;
        p {
            color: $message_success_text_color;
        }
    }
}
