.slideshow-wrap {
    position: relative;
    .caption {
        position: absolute;
        top: 43%;
        left: 43%;
        width: 60%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;

        h2 {
            font-family: $font-family-1;
            font-weight: bold;
            font-size: $font-size-60;
            color: $color-white;
            //text-shadow: 0 0 10px $color-black;
        }
        p {
            color: $color-white;
            //text-shadow: 0 0 10px $color-black;
            padding: 25px 0 40px 0;
            width: 90%;
        }
        a.btn-link {
          width: 255px;
        }
        @media screen and (max-width: 1750px) {
          top: 40%;
          left: 42%;
          @media screen and (max-width: 1650px) {
            h2 {
                font-size: 2.6rem;
            }
            @media screen and (max-width: 1582px) {
              top: 39%;
              left: 40%;
              width: 70%;
              h2 {
                  font-size: 2.45rem;
              }
              @media screen and (max-width: 1435px) {
                top: 36%;
                h2 {
                    font-size: 2.3rem;
                }
                @media screen and (max-width: 1365px) {
                  left: 45%;
                  width: 80%;
                  @media screen and (max-width: 1210px) {
                    top: 33%;
                    @media screen and (max-width: 1140px) {

                      h2 {
                          font-size: 2.1rem;
                      }
                      p {
                          padding: 20px 0 30px 0;
                      }
                      @media screen and (max-width: 1024px) {
                        top: 32%;
                        left: 38%;
                        width: 68%;
                          h2 {
                              font-size: 1.9rem;
                              br {
                                display: none;
                              }
                          }
                          p {
                            width: 100%;
                          }
                        @media screen and (max-width: $size-sm-max) {
                          top: 40%;
                          left: 43%;
                          width: 75%;
                          a.btn-link {
                            display: none;
                          }
                          @media screen and (max-width: $size-xs-max) {
                            top: 45%;
                            left: 50%;
                            width: 90%;
                            @media screen and (max-width: 375px) {
                              top: 40%;

                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }

    .fb-arrow {
        padding-top: 35px;
        position: absolute;
        right: 6.9%;
        bottom: 10%;
        z-index: 10;
        a.arrow {
            position: relative;
            top: 25px;
        }
        @media screen and (max-width: $size-md-max) {
          top: 20%;
          a.arrow {
            display: none;
          }
          @media screen and (max-width: $size-xs-max) {
              top: 15%;
          }
        }
    }

    .slideshow {
        width: 100%;
        overflow: hidden;
        position: relative;
        .placeholder {
            width: 100vw;
            height: auto;
            display: block;
            @media screen and (max-width: $size-slider-breakpoint){
              //  height: calc(100vw / 480 * 880);
              @media screen and (max-width: $size-xs-max) {
                  height: 700px;
               }
            }
        }
        &.mobile {
            display: none;
        }
        .slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 0;
                    right: 0;
                    bottom: 20%;
                    padding: 20px;
                    position: absolute;
                    background: rgba(0,0,0, 0.5);

                    h2 {
                        font-weight: 900;
                        text-align: center;
                        font-size: $font-size-46;
                        color: $color-white;
                        @media screen and (max-width: $size-sm-max) {
                            font-size: 2rem;
                        }
                    }
                }
            }
            img {
               width: 100vw!important;
               height: auto;
               display: block;
               position: relative;
            }
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
            }
            &.active {
                right: 0;
                left: 0;
                z-index: 10;
                opacity: 1;
                transition: 1.5s;
            }
          }
          @media screen and (max-width: 480px) {
                &.mobile {
                    display: block;
                }
                &.ecran {
                    display: none;
                }
          }
    }
}
