.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 65%;
        left: 10%;
        width: 100%;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            font-family: $font-family-1;
            font-weight: bold;
            font-size: $font-size-60;
            color: $color-white;
            padding-bottom: 25px;
            //text-shadow: 0 0 10px $color-black;
        }
        &--subtitle {
            width: 100%;
            font-family: $font-family-3;
            font-size: $font-size-20;
            font-weight: normal;
            color: $color-white;
            line-height: 1.5;
            //text-shadow: 0 0 10px $color-black;
        }
    }
}
@media screen and (max-width: $size-md-max) {
    .module-header {
        &--caption {
           left: 5%;
        }
    }
    @media screen and (max-width: 1024px) {
        .module-header {
            &--caption {
               width: 84%;
                &--title {
                    font-size: 2.5rem;
                }
                &--subtitle {
                    br {
                        display: none;
                    }
                }
            }
        }
    }
}


.module-header.realisations {
    @media screen and (max-width: 1500px) {
        .module-header--caption {
            left: 5%;
            h3 {
                font-size: 2.7rem;
            }
        }
        @media screen and (max-width: 1130px) {
            .module-header--caption {
                h3 {
                    font-size: 2.4rem;
                }
            }
            @media screen and (max-width: 1024px) {
                .module-header--caption {
                    h3 {
                        br {
                            display: none;
                        }
                    }
                }
                @media screen and (max-width: $size-sm-max) {
                    .module-header--caption {
                        h3 {
                            font-size: 2.2rem;
                            width: 90%;
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .module-header--caption {
                            top: 75%;
                            h3 {
                                font-size: 2rem;
                                width: 100%;
                            }
                        }
                        @media screen and (max-width: 375px) {
                            .module-header--caption {
                                h3 {
                                    font-size: 1.8rem;
                                }
                            }
                            @media screen and (max-width: 360px) {
                                .module-header--caption {
                                    h3 {
                                        font-size: 1.7rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.module-header-coordonnees {
    position: relative;
    .module-header--caption {
        top: 50%;
    }
    .section-coordonnees.desktop {
        background: #fff;
        padding: 50px;
        position: absolute;
        width: 950px;
        right: 0;
        bottom: 0;
        .left-side {
            width: 50%;
            margin-right: 50px;
        }
        .right-side {
            width: 50%;
        }
        h4 {
            font-family: $font-family-3;
            font-weight: normal;
            font-size: $font-size-24;
            color: $color-black;
            &.title {
                font-size: $font-size-26;
                font-weight: bold;
            }
        }
        p {
            font-size: $font-size-16;
            color: $color-black;
        }
        h5 {
            font-family: $font-family-3;
            font-weight: normal;
            font-size: $font-size-18;
            color: $color-black;
        }
    }
    @media screen and (max-width: 1650px) {
        .module-header--caption {
            top: 40%;
        }
        @media screen and (max-width: 1460px) {
            .module-header--caption {
                top: 55%;
            }   
            .section-coordonnees.desktop {
                display: none;
            }

            @media screen and (max-width: $size-md-max) {
                .module-header--caption {
                    left: 5%;
                }
                @media screen and (max-width: $size-sm-max) {
                    .module-header--caption {
                        top: 65%;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .module-header--caption {
                            top: 75%;
                            h3 br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}