// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

a.btn-link {
    background: $color-black;
    border-radius: 5px;
    height: 80px;
    transition: 0.3s;
    h4 {
        text-transform: uppercase;
        font-weight: normal;
        font-size: $font-size-18;
        letter-spacing: 3.6px;
        color: $color-white; 
    }
    &:hover {
        background: transparent;
        border: 3px solid $color-white;
        transition: 0.3s;
    }
}



/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';




/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-text-image {
    padding: 25px 0 0 0;
    position: relative;
    .left-side {
        background: #CC0016;
        position: absolute;
        width: 45%;
        bottom: 0;
        top: 20%;
        padding: 150px 2% 0 5%;
        h3 {
            font-family: $font-family-1;
            font-weight: bold;
            font-size: $font-size-64;
            color: #EDEDED;
            text-transform: initial;
            padding-bottom: 20px;
        }
        p {
            font-size: $font-size-18;
            line-height: 1.5;
            width: 80%;
            color: #EDEDED;
        }
        .section-arrow {
            background: #990010;
            width: 245px;
            height: 250px;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    .right-side {
        width: 70%;
        margin-left: auto;
    }
    @media screen and (max-width: 1680px){
        .left-side{
            h3 {
                font-size: 3rem;
            }
        } 
        @media screen and (max-width: 1590px){
            .left-side {
                padding: 125px 2% 0 5%;
                h3 {
                    font-size: 2.8rem;
                    br {
                        display: none;
                    }
                }
                p {
                    width: 87%;
                }
                .section-arrow {
                    width: 190px;
                    height: 210px;
                    .img-responsive {
                        width: 14%;
                    }
                }
            } 
            @media screen and (max-width: 1470px){
                .left-side {
                    padding: 100px 2% 0 5%;
                    h3 {
                        font-size: 2.65rem;
                    }
                } 
                @media screen and (max-width: 1392px){
                    .left-side {
                        width: 51%;
                        padding: 100px 5% 0 5%;
                    }
                    @media screen and (max-width: 1235px){
                        flex-direction: column;
                        .left-side {
                            width: 100%;
                            padding: 75px 5% 285px 5%;
                            position: relative;
                            p {
                                width: 75%;
                            }
                        }
                        .right-side {
                            width: 100%;
                            margin-left: initial;
                        }
                        @media screen and (max-width: $size-sm-max) {
                            .left-side {
                                p {
                                    width: 100%;
                                }
                            }
                            @media screen and (max-width: $size-xs-max) {
                                .left-side {
                                    padding: 75px 5% 225px 5%;
                                    .section-arrow {
                                        width: 155px;
                                        height: 185px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-images {
    .title {
        padding: 100px 5%;
        h3 {
            font-family: $font-family-1;
            font-weight: 500;
            font-size: $font-size-70;
            color: $color-black;
            text-transform: initial;
            line-height: 1.3;
        }
    }
    .section-images.accordion {
        font-size: 0;
        height: 100%;
        .image.accordion__item {
            font-size: 1rem;
            display: inline-block;
            vertical-align: top;
            height: 100%;
            min-height: 720px;
            box-sizing: border-box;
            position: relative;
            outline: none;
            background-size: cover;
            // border: 1px solid black;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            &:nth-child(1) {
                background-image: url("../images/accueil_slide_01.jpg");
            }
            &:nth-child(2) {
                background-image: url("../images/accueil_slide_02.jpg");
            }
            &:nth-child(3) {
                background-image: url("../images/accueil_slide_03.jpg");
            }

            div.txt-box {
                position: absolute;
                top: 75%;
                left: 50%;
                transform: translate(-50% , -50%);
                div.wrapper {
                    position: relative;
                }
                h3 {
                    color: $color-white;
                    font-size: 189px;
                    transition: .5s;
                    text-align: center;
                    &.overlay {
                        opacity: 0;
                        visibility: hidden;
                        transition: .5s;
                        position: absolute;
                        top: 0;
                    }
                }
            }
            &.active {
                div.txt-box {
                    h3 {
                        font-size: 70px;
                        opacity: 0;
                        visibility: hidden;
                        transition: .5s;
                        &.overlay {
                            transition: .5s;
                            opacity: 1;
                            transform: translate(-50% , 0);
                            visibility: visible;;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1647px) {
        .title {
            h3 {
                br {
                    display: none;
                }
            }
        }
        @media screen and (max-width: 1350px) {
            .title {
                h3 {
                    font-size: 3.25rem;
                }
            }
            @media screen and (max-width: 1130px) {
                .title {
                    h3 {
                        font-size: 3rem;
                    }
                }
                @media screen and (max-width: 1024px) {
                    .title {
                        h3 {
                            font-size: 2.85rem;
                        }
                    }
                    .section-images.accordion {
                        .image.accordion__item.active {
                            div.txt-box h3 {
                                font-size: 50px;
                            }
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .title {
                            h3 {
                                font-size: 2.7rem;
                                text-align: center;
                            }
                        }
                        .slideshow.mobile.slideshow_accordion img.placeholder {
                            height: initial;
                        }
                        @media screen and (max-width: 375px) {
                            .title {
                                h3 {
                                    font-size: 2.3rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-services {
    padding: 100px 5%;
    background: #FF001B07;
    margin-top: 20px;
    h4 {
        font-family: $font-family-2;
        font-weight: normal;
        font-size: $font-size-70;
        color: $color-black;
    }
    .main-content {
        padding-top: 60px;
        .owl-item {
            background: #A4404B;
            &:nth-child(odd) {
                background: #66000B;
            }
        }
        .bloc {
            padding-left: 0;
            padding-right: 0;
            height: 340px;
            .icon {
                .img-responsive {
                    width: initial;
                    margin: auto;
                }
            }
            .text {
                text-align: center;
                padding-top: 10px;
                h4 {
                    font-family: $font-family-2;
                    font-size: $font-size-36;
                    color: $color-white;
                }
            }
        }          
    }
    @media screen and (max-width: 1400px) {
        h4 {
            font-size: 3.25rem;
            br {
                display: none;
            }
        }
        @media screen and (max-width: $size-sm-max) {
            h4 {
                font-size: 2.8rem;
            }
            @media screen and (max-width: $size-xs-max) {
                padding: 75px 5%;
                .main-content {
                    .bloc {
                        margin: auto;
                    }
                }
            }
        }
    }
}

#section-text-btn {
    padding: 75px 5%;
    .left-side {
        width: 55%;
        h3 {
            font-family: $font-family-3;
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-black;
            line-height: 1.3;
        }
    }
    a.btn-link {
        width: 300px;
        &:hover {
            border: 3px solid $color-black;
            h4 {
                color: $color-black;
            }
        }
    }
    @media screen and (max-width: 1430px) {
        .left-side {
            width: 65%;
        }
        @media screen and (max-width: 1100px) {
            flex-direction: column;
            align-items: initial;
            .left-side {
                width: 100%;
                margin-bottom: 50px;
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side {
                    h3 br {
                        display: none;
                    }
                }
                a.btn-link {
                    width: 250px;
                }
            }
        }
    }
}

/*******************************************************************************
* PRODUITS
*******************************************************************************/

#section-produit-left {
    margin-top: 25px;
    .image-side {
        width: 50%;
        margin-right: 15px;
    }
    .text-side {
        width: 50%;
        background: #00000017;
        text-align: center;
        padding: 0 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-black;
            padding-bottom: 60px;
        }
        p {
            color: $color-black;
            width: 90%;
        }
        a.btn-link {
            width: 260px;
            &:hover {
                border: 3px solid $color-black;
                h4 {
                    color: $color-black;
                }
            }
        }
        .border {
            background: #FF001B;
            width: 215px;
            height: 12px;
        }
    }
    @media screen and (max-width: 1600px) {
        .text-side {
            h3 {
                padding-bottom: 35px;
            }
        }
        @media screen and (max-width: 1455px) {
            .text-side {
                padding: 0 3%;
                p {
                    width: 100%;
                }
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column;
                .image-side {
                    width: 70%;
                    margin: auto;
                }
                .text-side {
                    width: 70%;
                    margin: auto;
                    padding: 75px 5%;
                }
                @media screen and (max-width: $size-sm-max) {
                    .image-side, .text-side {
                        width: 100%;
                    }
                }
            }
        }
    }
}

#section-produit-right {
    margin-top: 25px;
    .image-side {
        width: 50%;
        margin-left: 15px;
    }
    .text-side {
        width: 50%;
        background: #00000017;
        text-align: center;
        padding: 0 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-black;
            padding-bottom: 60px;
        }
        p {
            color: $color-black;
            width: 90%;
        }
        a.btn-link {
            width: 260px;
            &:hover {
                border: 3px solid $color-black;
                h4 {
                    color: $color-black;
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .text-side {
            h3 {
                padding-bottom: 35px;
            }
        }
        @media screen and (max-width: 1455px) {
            .text-side {
                padding: 0 3%;
                p {
                    width: 100%;
                }
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column-reverse;
                .image-side {
                    width: 70%;
                    margin: auto;
                }
                .text-side {
                    width: 70%;
                    margin: auto;
                    padding: 75px 5%;
                }
                @media screen and (max-width: $size-sm-max) {
                    .image-side, .text-side {
                        width: 100%;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

section#toolbar {
    padding: 25px 5%;
    background: $color-black;
    ul.menu-filter {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li.filter-item {
            a {
                font-weight: 600;
                font-size: $font-size-18;
                color: $color-white;
                text-transform: uppercase;
                cursor: pointer;
                transition: all 0.3s ease-out;
                &:hover {
                    color: $color-1;
                    transition: all 0.3s ease-out;
                }
            }
            span {
                font-size: 18px;
                font-weight: normal;
                color: $color-white;
                padding-left: 30px;
            }
            &:last-child span {
                display: none;
            }
        }
        li.filter-item:nth-child(even) {
            margin: 0 30px;
        }
    }
    @media screen and (max-width: 1500px) {
        ul.menu-filter {
            li.filter-item {
                 span {
                    padding-left: 15px;
                }
                &:nth-child(even) {
                    margin: 0 15px;
                }
            }
        }
        @media screen and (max-width: 1300px) {
            ul.menu-filter {
                li.filter-item {
                     span {
                        padding-left: 10px;
                    }
                    &:nth-child(even) {
                        margin: 0 10px;
                    }
                }
            }
            @media screen and (max-width: 1130px) {
                ul.menu-filter {
                    flex-direction: column;
                    align-items: center;
                    li.filter-item span {
                        display: none;
                    }
                    li.filter-item:nth-child(2) {
                        margin: 0;
                    }
                }
            }
        }
    }
}

section.gallerie-products {
    padding: 50px 5% 100px 5%;

    .section-gallerie {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .grid-item {
            padding-bottom: 50px;
            margin: 7px;
            width: 32%;
            min-width: 250px;
            img {
                width: 100%;
            }
        }

        .header-gallery-section {
            width: 100%;
            flex: none;
            border-top: 1px solid #CACBD5;
            padding-top: 35px;

            h4 {
                font-weight: bold;
                font-size: $font-size-30;
                color: #203E45 !important;
            }
            &:first-child {
                border-top: none;
                padding-top: 0;
                a#category9 {
                    padding: 30px 0;
                }
                a.scroll-link img {
                    display: none;
                }
            }
        }

        @media screen and (max-width: $size-md-max){
            .grid-item {
                width: 48%;
            }
            @media screen and (max-width: $size-sm-max){
                .grid-item {
                    width: 47%;
                }
                @media screen and (max-width: $size-xs-max){
                    .grid-item {
                        width: 100%;
                    }
                }
            }
        }

        @media screen and (max-width: 600px){
            flex-direction: column;
            justify-content: center;
        }

        .grid-item {
            .section-title {
                padding-top: 20px;
                h4 {
                    font-weight: normal;
                    font-size: $font-size-20;
                    color: #203E45;
                    cursor: initial;
                }
            }
        }
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 50px 5% 75px 5%;
    }
}

/*******************************************************************************
* SOUMISSION
*******************************************************************************/

#section-formulaire {
    padding: 75px 5%;
    h3.title {
        font-weight: bold;
        font-size: $font-size-30;
        color: $color-black;
        text-transform: uppercase;
        padding-left: 15px;
        padding-bottom: 25px;
    }
    @media screen and (max-width: $size-xs-max) {
        h3.title {
            text-align: center;
            font-size: 1.6rem;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees.mobile {
    background: #fff;
    padding: 75px 5%;
    display: none;
    .left-side {
        width: 50%;
        margin-right: 50px;
    }
    .right-side {
        width: 50%;
    }
    h4 {
        font-family: $font-family-3;
        font-weight: normal;
        font-size: $font-size-24;
        color: $color-black;
        &.title {
            font-size: $font-size-26;
            font-weight: bold;
        }
    }
    p {
        font-size: $font-size-16;
        color: $color-black;
    }
    h5 {
        font-family: $font-family-3;
        font-weight: normal;
        font-size: $font-size-18;
        color: $color-black;
    }
    @media screen and (max-width: 1460px) {
        display: flex;
        padding: 75px 5% 0 5%;
        @media screen and (max-width: $size-xs-max) {
            flex-direction: column;
            padding: 60px 5% 0 5%;
            .left-side {
                width: 100%;
                margin: 0 0 25px 0;
                .icon {
                    display: none;
                }
            }
            .right-side {
                width: 100%;
                .tel {
                    padding-bottom: 25px;
                }
            }
        }
    }
}

#section-heures-map {
    padding: 75px 0 75px 10%;
    .left-side {
        width: 50%;
        h3 {
            font-weight: bold;
            font-size: $font-size-46;
            color: $color-black;
            text-transform: initial;
            padding-bottom: 25px;
        }
        h4 {
            font-family: $font-family-3;
            font-weight: bold;
            font-size: $font-size-18;
            line-height: 2.3;
            color: $color-black;
        }
        h5 {
            font-family: $font-family-3;
            font-weight: normal;
            font-size: $font-size-18;
            line-height: 2.3;
            color: $color-black;
        }
        .jour {
            width: 50%;
        }
    }
    .right-side {
        width: 85%;
    }
    @media screen and (max-width: 1450px) {
        .left-side {
            margin-right: 5%;
        }
        @media screen and (max-width: $size-md-max) {
            padding: 75px 5%;
            .right-side {
                width: 50%;
            }
            @media screen and (max-width: $size-sm-max) {
                flex-direction: column;
                .left-side {
                    width: 100%;
                    margin: 0 0 50px 0;
                    .jour {
                        width: 28%;
                    }
                }
                .right-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    .left-side {
                        .jour {
                            width: 45%;
                        }
                    }
                }
            }
        }
    }
}

#content {
    h3#firstHeading, {
        color: $color-1;
    }
    p a {
        color: $color-black;
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   

}
